import { lazy } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';

import { pageTypeConstants as PAGE_TYPES } from '../constants/pageTypeConstants';
import { MIN_THIN_PAGE_COUNT } from '../constants/sbConstants';

import { BuySharedHead } from './BuySharedHead/BuySharedHead';
import { BuySharedBreadcrumbs } from './BuySharedBreadcrumbs/BuySharedBreadcrumbs';
import { BuySharedTitle } from './BuySharedTitle/BuySharedTitle';
import { BuySharedRelatedPages } from './BuySharedRelatedPages/BuySharedRelatedPages';
import { BuySharedResultContainer } from './BuySharedResultContainer/BuySharedResultContainer';
import { SharedPagination } from '../shared/SharedPagination/SharedPagination';
import { BuySharedDescription } from './BuySharedDescription/BuySharedDescription';
import { BuySharedNoInventoryAvailable } from './BuySharedNoInventoryAvailable/BuySharedNoInventoryAvailable';
import { SharedRecentSales } from '../shared/SharedRecentSales/SharedRecentSales';
import { BuySharedCMSFAQ } from './BuySharedCMSFAQ/BuySharedCMSFAQ';
import { SharedFAQ } from '../shared/SharedFAQ/SharedFAQ';
import { SharedMobileLinksBlock } from '../shared/SharedMobileLinksBlock/SharedMobileLinksBlock';
import { SharedBottomModule } from '../shared/SharedBottomModule/SharedBottomModule';
import { LoadingComponent } from '../components/global/LoadingComponent';
import { SharedWordpressArticles } from '../shared/SharedWordpressArticles/SharedWordpressArticles';
import { SharedSaveSearchContextProvider } from '../shared/SharedSaveSearchContext/SharedSaveSearchContext';
import { SharedFollowSearchBanner } from '../shared/SharedFollowSearchBanner/SharedFollowSearchBanner';
import { BuyMobileRefineMenu } from './BuyMobileRefineMenu/BuyMobileRefineMenu';
import { SbMobileAppliedFilters } from '../finding/SbMobileAppliedFilters/SbMobileAppliedFilters';
import { BuySharedRefetchContainer } from './BuySharedRefetchContainer';
import { CurrencyProvider } from 'dibs-buyer-layout/exports/CurrencyProvider';
import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';
import { BuyMobileCategoryTiles } from './BuyCategoryTiles/BuyMobileCategoryTiles';
import { BuySharedPersonalizedSearchItems } from './BuySharedPersonalizedSearchItems/BuySharedPersonalizedSearchItems';
import { useDwelledOnPage } from 'dibs-buyer-layout/exports/dwelledOnPage';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';

import classnames from 'classnames';
import styles from './BuySharedLayout.scss';

const SharedYMALLazyComponent = lazy(() =>
    import(/* webpackChunkName: "SharedYMAL" */ '../shared/SharedYMAL/SharedYMAL')
);

const BuyMobileLayoutComponent = ({ viewer, relay, isClient, page, refetchInFlight }) => {
    const {
        buyPage,
        user = null,
        isWordpressArticlesEnabled,
        isNlgContentEnabled,
        isRelatedBuyPagesEnabled,
    } = viewer;
    const {
        internalBuyLinks,
        bottomModule,
        filters,
        displayMaxNumberOfPages,
        totalResults,
        appliedFilters,
        displayUriRef,
        meta,
    } = buyPage;

    const { buyPageAvailableItemCount } = meta || {};
    const searchTerm = appliedFilters?.find(f => f?.name === PAGE_TYPES.BUY)?.values?.[0]
        ?.displayName;

    // Available item count for redesign is increased from 4 to 30 (GROWTH-7399)
    // UPDATE: Keeping redesign for up to 30 available items forever (GROWTH-7528)
    const showBuyPageRedesign = buyPageAvailableItemCount <= MIN_THIN_PAGE_COUNT;

    useDwelledOnPage(PAGE_TYPE.SEARCH);

    return (
        <SharedSaveSearchContextProvider
            itemSearch={buyPage}
            user={user}
            uriRef={displayUriRef}
            searchTerm={searchTerm}
        >
            <>
                <BuySharedHead buyPage={buyPage} />
                <BuySharedRefetchContainer
                    viewer={viewer}
                    buyPage={buyPage}
                    refetch={relay.refetch}
                />
                {refetchInFlight && <LoadingComponent />}
                <BuySharedBreadcrumbs buyPage={buyPage} />
                <div className={styles.headerLeftWrapper}>
                    <div className={styles.headerLeft}>
                        <BuySharedTitle buyPage={buyPage} />
                    </div>
                </div>

                {isRelatedBuyPagesEnabled && <BuySharedRelatedPages buyPage={buyPage} />}

                {buyPageAvailableItemCount > MIN_THIN_PAGE_COUNT ? (
                    <CurrencyProvider
                        render={({ currency }) => (
                            <>
                                <BuyMobileRefineMenu
                                    viewer={viewer}
                                    buyPage={buyPage}
                                    user={user}
                                    filters={filters}
                                    isClient={isClient}
                                    currency={currency}
                                />
                                <SbMobileAppliedFilters
                                    user={user}
                                    itemSearch={buyPage}
                                    currency={currency}
                                />
                            </>
                        )}
                    />
                ) : (
                    <div className={styles.spacer} />
                )}

                <div className={styles.mainContent}>
                    <div className={styles.container}>
                        {totalResults ? (
                            <>
                                <BuySharedResultContainer
                                    viewer={viewer}
                                    buyPage={buyPage}
                                    user={user}
                                />
                                <SharedPagination
                                    currentPage={page}
                                    numberOfPages={displayMaxNumberOfPages}
                                    displayUriRef={displayUriRef}
                                    hideHref
                                />
                            </>
                        ) : (
                            <BuySharedNoInventoryAvailable isMobile />
                        )}

                        {showBuyPageRedesign && (
                            <>
                                <SharedRecentSales
                                    user={user}
                                    viewer={viewer}
                                    itemSearch={buyPage}
                                    isMobile
                                    isClient={isClient}
                                    hideRecentSoldPrice={!user}
                                    lazyLoadStartIndex={2}
                                />
                                <ClientSuspense fallback="">
                                    <SharedYMALLazyComponent
                                        user={user}
                                        viewer={viewer}
                                        itemSearch={buyPage}
                                        isMobile
                                        isClient={isClient}
                                    />
                                </ClientSuspense>
                                <BuySharedPersonalizedSearchItems
                                    viewer={viewer}
                                    user={user}
                                    itemSearch={buyPage}
                                    isMobile
                                    isClient={isClient}
                                />
                            </>
                        )}

                        {/* Follow Search */}
                        <SharedFollowSearchBanner itemSearch={buyPage} user={user} />

                        <BuyMobileCategoryTiles itemSearch={buyPage} />

                        {isNlgContentEnabled && (
                            <SharedBottomModule
                                className={classnames(styles.bottomModule, {
                                    [styles.topMargin]: showBuyPageRedesign,
                                })}
                                bottomModule={bottomModule}
                            />
                        )}
                        <BuySharedDescription buyPage={buyPage} />
                    </div>
                </div>

                <BuySharedCMSFAQ buyPage={buyPage} />

                {/* Questions & Answers */}
                <SharedFAQ itemSearch={buyPage} />

                {isWordpressArticlesEnabled && (
                    <SharedWordpressArticles
                        itemSearch={buyPage}
                        headerClassName={styles.wpArticlesHeader}
                    />
                )}

                <SharedMobileLinksBlock
                    item={null}
                    viewer={viewer}
                    itemSearch={buyPage}
                    links={internalBuyLinks}
                    borderTop
                    popularSearchTermClassName={styles.popularSearchCategoryTerm}
                />
            </>
        </SharedSaveSearchContextProvider>
    );
};

function mapStateToProps(state) {
    const { filters, relayVariables } = state;
    const { generatedUriRef, page } = filters;
    const {
        variables: { isClient },
    } = relayVariables;

    return {
        page,
        generatedUriRef,
        refetchInFlight: filters.refetchInFlight,
        isClient,
    };
}

BuyMobileLayoutComponent.propTypes = {
    viewer: PropTypes.object.isRequired,
    relay: PropTypes.object.isRequired,
    generatedUriRef: PropTypes.string,
    isClient: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    refetchInFlight: PropTypes.bool.isRequired,
};

export const BuyMobileLayout = createRefetchContainer(
    connect(mapStateToProps)(BuyMobileLayoutComponent),
    {
        viewer: graphql`
            fragment BuyMobileLayout_viewer on Viewer {
                isWordpressArticlesEnabled: isEnabledByLocale(featureName: "WP_EDITORIAL")
                isNlgContentEnabled: isEnabledByLocale(featureName: "NLG_CONTENT")
                isRelatedBuyPagesEnabled: isEnabledByLocale(featureName: "RELATED_BUY_PAGES")
                ...BuySharedRefetchContainer_viewer
                buyPage: itemSearch(
                    userId: $personalizationId
                    guestId: $guestId
                    uriRef: $uriRef
                    localeOrigin: $localeOrigin
                    first: $first
                    page: $page
                    buyPageId: $rootId
                    userCountryCode: $userCountryCode
                    regionsList: $regionsList
                    priceBookName: $priceBookName
                ) {
                    totalResults
                    displayMaxNumberOfPages
                    displayUriRef
                    appliedFilters {
                        name
                        values {
                            displayName
                        }
                    }
                    meta {
                        buyPageAvailableItemCount
                    }
                    filters {
                        ...BuyMobileRefineMenu_filters
                    }
                    bottomModule {
                        ...SharedBottomModule_bottomModule
                    }
                    ...BuySharedRefetchContainer_buyPage
                    ...BuySharedBreadcrumbs_buyPage
                    ...BuySharedTitle_buyPage
                    ...BuySharedRelatedPages_buyPage
                    ...BuySharedResultContainer_buyPage
                    ...SharedRecentSales_itemSearch
                    ...SharedYMAL_itemSearch @arguments(priceBookName: $priceBookName)
                    ...BuySharedDescription_buyPage
                    ...BuySharedHead_buyPage
                    ...BuySharedCMSFAQ_buyPage
                    ...SharedFAQ_itemSearch
                    ...SharedWordpressArticles_itemSearch
                    ...SharedMobileLinksBlock_itemSearch
                    internalBuyLinks {
                        ...SharedMobileLinksBlock_links
                    }
                    ...BuyMobileRefineMenu_buyPage
                    ...SbMobileAppliedFilters_itemSearch
                    ...SharedSaveSearchContext_itemSearch
                    ...SharedFollowSearchBanner_itemSearch
                    ...BuyMobileCategoryTiles_itemSearch
                    ...BuySharedPersonalizedSearchItems_itemSearch
                        @arguments(priceBookName: $priceBookName)
                }
                ...SharedRecentSales_viewer
                ...SharedMobileLinksBlock_viewer
                ...BuySharedResultContainer_viewer
                ...BuyMobileRefineMenu_viewer
                ...BuySharedPersonalizedSearchItems_viewer
                user(userId: $userId) @include(if: $fetchUser) {
                    ...BuyMobileRefineMenu_user
                    ...SbMobileAppliedFilters_user
                    ...BuySharedResultContainer_user
                    ...SharedRecentSales_user
                    ...SharedYMAL_user
                    ...SharedSaveSearchContext_user
                    ...SharedFollowSearchBanner_user
                    ...BuySharedPersonalizedSearchItems_user
                }
            }
        `,
    },
    graphql`
        query BuyMobileLayoutRefetchQuery(
            $uriRef: String!
            $localeOrigin: String!
            $isClient: Boolean!
            $isTrade: Boolean!
            $showSeller: Boolean!
            $first: Int!
            $page: Int!
            $rootId: String!
            $fetchBuy: Boolean!
            $userZipCode: String = ""
            $userCountryCode: String = ""
            $fetchRegionalInfo: Boolean = false
            $userId: String = ""
            $personalizationId: String = ""
            $guestId: String = ""
            $rerankUserId: String = ""
            $rerankGuestId: String = ""
            $fetchUser: Boolean!
            $skipUser: Boolean = true
            $followSearchTypes: [types] = [SEARCH, USER_PREFERENCE]
            $followSearchPages: [String]
            $priceBookName: String
            $regionsList: [String]
            $pageDisplayEnum: PageDisplayEnum = searchAndBrowse
        ) {
            viewer {
                isWordpressArticlesEnabled: isEnabledByLocale(featureName: "WP_EDITORIAL")
                ...BuySharedRefetchContainer_viewer
                buyPage: itemSearch(
                    userId: $personalizationId
                    guestId: $guestId
                    uriRef: $uriRef
                    localeOrigin: $localeOrigin
                    first: $first
                    page: $page
                    buyPageId: $rootId
                    userCountryCode: $userCountryCode
                    regionsList: $regionsList
                    priceBookName: $priceBookName
                ) @include(if: $fetchBuy) {
                    totalResults
                    displayMaxNumberOfPages
                    displayUriRef
                    appliedFilters {
                        name
                        values {
                            displayName
                        }
                    }
                    filters {
                        ...BuyMobileRefineMenu_filters
                    }
                    meta {
                        buyPageAvailableItemCount
                    }
                    bottomModule {
                        ...SharedBottomModule_bottomModule
                    }
                    ...BuySharedRefetchContainer_buyPage
                    ...BuySharedBreadcrumbs_buyPage
                    ...BuySharedTitle_buyPage
                    ...BuySharedRelatedPages_buyPage
                    ...BuySharedResultContainer_buyPage
                    ...SharedRecentSales_itemSearch
                    ...SharedYMAL_itemSearch @arguments(priceBookName: $priceBookName)
                    ...BuySharedDescription_buyPage
                    ...BuySharedHead_buyPage
                    ...BuySharedCMSFAQ_buyPage
                    ...SharedFAQ_itemSearch
                    ...SharedWordpressArticles_itemSearch
                    ...SharedMobileLinksBlock_itemSearch
                    internalBuyLinks {
                        ...SharedMobileLinksBlock_links
                    }
                    ...BuyMobileRefineMenu_buyPage
                    ...SbMobileAppliedFilters_itemSearch
                    ...SharedSaveSearchContext_itemSearch
                    ...BuyMobileCategoryTiles_itemSearch
                    ...BuySharedPersonalizedSearchItems_itemSearch
                        @arguments(priceBookName: $priceBookName)
                }

                user(userId: $userId) @include(if: $fetchUser) @skip(if: $skipUser) {
                    ...BuyMobileRefineMenu_user
                    ...SbMobileAppliedFilters_user
                    ...BuySharedResultContainer_user
                    ...SharedRecentSales_user
                    ...SharedYMAL_user
                    ...SharedSaveSearchContext_user
                    ...SharedFollowSearchBanner_user
                    ...BuySharedPersonalizedSearchItems_user
                }
                ...BuySharedResultContainer_viewer
                ...SharedRecentSales_viewer
                ...BuyMobileRefineMenu_viewer
                ...BuySharedPersonalizedSearchItems_viewer
            }
        }
    `
);
